import React from "react";
import { graphql } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { ArticlePost, CallToAction, RelatedPosts } from "../components";
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
// TODO: show a CTA
// TODO: show an about the author block

export default function Template({ data }: { data: any }) {
  const { article, relatedAll, relatedPublished } = data;
  const { frontmatter, body } = article;
  let relatedPosts;
  const related = activeEnv === "development" ? relatedAll : relatedPublished;

  if (related && Array.isArray(related.edges)) {
    relatedPosts = related.edges.filter(({ node }: { node: any }) => {
      return node.childMdx.frontmatter.slug !== frontmatter.slug;
    });
  }

  return (
    <LayoutMain
      isBlogPost={true}
      frontmatter={frontmatter}
      title={frontmatter.title}
      metaTitle={frontmatter.metaTitle}
    >
      <div className="blog-post-container">
        <ArticlePost article={{ body, ...frontmatter }} />
        <CallToAction />
        {relatedPosts && relatedPosts.length ? (
          <RelatedPosts posts={relatedPosts} />
        ) : (
          ""
        )}
      </div>
    </LayoutMain>
  );
}

export const ArticleBySlug = graphql`
  query ($slug: String!, $categorySlug: String!) {
    article: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        slug
        date
        category
        categorySlug
        author
        description
        status
        bannerAlt
        keywords
        metaDescription
        metaTitle
        banner {
          id
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    relatedAll: allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: { frontmatter: { categorySlug: { eq: $categorySlug } } }
      }
      sort: { fields: [childMdx___frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
    relatedPublished: allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: {
          frontmatter: {
            categorySlug: { eq: $categorySlug }
            status: { eq: "published" }
          }
        }
      }
      sort: { fields: [childMdx___frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  }
`;
